import { billOfMaterialService } from '@/api/';
import { TagsViewModule } from '@/store/modules/tags-view';
import { messageError, translation } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { SelectMaterial, SelectWarehouse } from '@/views/dialogs';
import { BillOfMaterialDetails, MaterialBomDetailList } from '@/resource/model';
import BomList from './bom-list/bom-list.vue';
import { PublicApproveStatusEnum, ResourceStatusEnum } from '@/resource/enum';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddMaterial',
  components: {
    SelectMaterial,
    SelectWarehouse,
    BomList
  }
})
export default class DetailsBom extends Vue {
  /**
   *  物料清单ID
   */
  public id: number | null = null;

  public disabled: boolean = true;

  public rowData: MaterialBomDetailList | null = null;

  public paymentOptions: Array<{ label: string; value: string }> = [];

  public form: BillOfMaterialDetails | null = null;
  private get receiveMode(): string {
    return this.rowData!.receiveMethod ? translation(`receiveModeEnum.${this.rowData!.receiveMethod}`) : '';
  }
  private get attribute(): string {
    return this.form?.attribute ? translation(`materialPropertyEnum.${this.form.attribute}`) : '';
  }

  private get getApproveStatusI18Key(): string {
    return translation('publicApproveStatus.' + PublicApproveStatusEnum[this.form!.approveStatus]);
  }
  private get getStatusI18Key(): string {
    let res;
    switch (this.form!.enableStatus!) {
      case ResourceStatusEnum.using:
        res = 'common.startUsing';
        break;
      case ResourceStatusEnum.disabled:
        res = 'common.disabled';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return translation(res);
  }

  public setRowData(row: MaterialBomDetailList): void {
    this.rowData = row;
  }

  public created(): void {
    this.id = Number(this.$route.query.id);
    if (this.id > 0) {
      this.getDetails(this.id!);
    }
  }
  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    TagsViewModule.DelView(this.$route);
    next();
  }
  private getDetails(id: number): void {
    billOfMaterialService
      .getById(id)
      .then(res => {
        this.form = res;
        this.rowData = this.form?.detailList[0] || [];
      })
      .catch(error => {
        messageError(error);
      });
  }
}
