import { MaterialBomDetailList } from '@/resource/model';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { SelectMaterial } from '@/views/dialogs';
import Decimal from 'decimal.js';
import { decimalToNumber6, translation } from '@/utils';

@Component({
  name: 'BomList',
  components: { SelectMaterial }
})
export default class BomList extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return [];
    }
  })
  public materialBomDetailList!: Array<MaterialBomDetailList> | null;
  public tableOptions: OsTableOption<Partial<MaterialBomDetailList>> = {
    loading: false,
    data: [],
    rowKey: () => {
      return 'uuid';
    },
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<MaterialBomDetailList>> = [
    {
      type: 'index',
      prop: 'id',
      fixed: true,
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'materialCode',
      label: 'materialBom.materialCode',
      minWidth: '200px'
    },
    {
      prop: 'materialName',
      label: 'materialBom.materialName',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'specification',
      label: 'materialBom.specification',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'materialUnitName',
      label: 'materialList.baseUnit',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'type',
      label: 'materialBom.type',
      minWidth: '180px',
      formatter: (row: object): string => {
        return translation(`usageTypeEnum.${(row as MaterialBomDetailList).type}`);
      }
    },
    {
      prop: 'typeParam',
      label: 'materialBom.typeParam',
      minWidth: '160px',
      formatter: (row: object): string => {
        return translation(`usageCalculationParametersEnum.${(row as MaterialBomDetailList).typeParam}`);
      }
    },
    {
      prop: 'numerator',
      label: 'materialBom.numerator',
      minWidth: '160px'
    },
    {
      prop: 'denominator',
      label: 'materialBom.denominator',
      minWidth: '160px',
      formatter: (row: object): number => {
        return decimalToNumber6(new Decimal((row as MaterialBomDetailList).denominator));
      }
    },
    {
      prop: 'fixedAttrition',
      label: 'materialBom.fixedAttrition',
      minWidth: '160px',
      formatter: (row: object): number => {
        return decimalToNumber6(new Decimal((row as MaterialBomDetailList).fixedAttrition));
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '160px'
    }
    // {
    //   prop: 'taxAmount',
    //   label: 'billing.taxAmount',
    //   minWidth: '160px',
    //   formatter: (row: object): number => {
    //     return decimalToNumber2(new Decimal((row as MaterialBomDetailList).taxAmount));
    //   }
    // },
  ];

  public typeOptions: Array<{ label: string; value: string }> = [];
  public usageCalculationParametersOptions: Array<{ label: string; value: string }> = [];
  public handleRowClick(row: MaterialBomDetailList): void {
    this.$emit('setRowData', row);
  }
  public created(): void {
    this.tableOptions.data = this.materialBomDetailList || [];
  }

  @Watch('materialBomDetailList', { immediate: true })
  private watchTableData(): void {
    this.tableOptions.data = this.materialBomDetailList || [];
  }
}
