import { render, staticRenderFns } from "./details-bom.vue?vue&type=template&id=27e44913&scoped=true&"
import script from "./details-bom.ts?vue&type=script&lang=ts&"
export * from "./details-bom.ts?vue&type=script&lang=ts&"
import style0 from "./details-bom.scoped.scss?vue&type=style&index=0&id=27e44913&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e44913",
  null
  
)

export default component.exports